import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import {Link} from 'gatsby'
import * as Bs from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'
// import startupmag from "../../assets/image/png/meilleure-banque.png";
import {StaticImage} from 'gatsby-plugin-image';
import Newsletter from '../../components/Newsletter/bankbankMerci'

import {
  Title,
  Section,
  Box,
  Text,
} from "../../components/Core";

import { device } from "../../utils";

const SectionStyled = styled(Section)`
  
  background: white;
  background-size: cover;
  background-position: bottom left;
  
  padding-bottom: 200px;
  @media ${device.lg} {
    background-position: center;
  }
`;




const Hero = () => {

  
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled>
        <div className="pt-5"></div>
        <Container id='heroMargin' style={{height: 700}}>
        <Newsletter/>
 
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
