import React from "react";
import {Helmet} from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import {Link} from 'gatsby'
import * as BS from "react-bootstrap"
import { Container} from "react-bootstrap";
import Header from "../components/HeaderNews";
import Footer from "../components/FooterLanding";
import Hero from "../sections/landing2/HeroClubMerci";
import HeroFooter from "../sections/landing2/HeroClubFooter";
import { Post } from "../components/Core";
import Content1 from "../sections/landing2/Content1";
import {StaticImage} from 'gatsby-plugin-image';
import banque1 from "../assets/logos/black/boursorama-banque-black.png";
import banque2 from "../assets/logos/black/fortuneo-banque-black.png";
import banque3 from "../assets/logos/black/hsbc-black.png";
import banque4 from "../assets/logos/black/ing-black.png";
import banque5 from "../assets/logos/black/monabanq-black.png";
import banque6 from "../assets/logos/black/societe-general-black.png";

import BlogList from "../sections/blog/ClubOneTwoThree";
import Testimonials from "../components/Newsletter/testimonials"
import WhatCol from "../components/Newsletter/whatCol"
import FAQ from "../components/Newsletter/faq"
const LandingPage2 = () => {


  return (
    <>
      <PageWrapper footerDark>
      <Helmet>
                <meta charSet="utf-8" />
                <title>BankBank - Comparateur de banques personnelles et banques pro</title>
                <meta name="description" content="
              Trouvez la meilleure banque selon votre profile avec notre comparateur : comparez les offres par frais, cartes bancaires et coûts de tenue de compte." />
        </Helmet>

        <Header />

       
        <Hero />
        {/* <BS.Container style={{width: 1400, marginTop: 40, marginBottom: 100}}>
      <BS.Row style={{background: 'white', maxWidth: 880, margin: 'auto' }}>
               <img src={banque1} alt="" id='logoMobile' style={{height: 45}}/>
               <img src={banque2} alt="" id='logoMobile' style={{height: 45, marginLeft: 0}}/>
               <img src={banque3} alt="" id='logoMobile' style={{height: 45, marginLeft: 0}}/>
               <img src={banque4} alt="" id='logoMobile' style={{height: 45, marginLeft: -15}}/>
               <img src={banque5} alt="" id='logoMobile' style={{height: 45, marginLeft: 0}}/>
               <img src={banque6} alt="" id='logoMobile' style={{height: 45, marginLeft: 0}}/>
   
               </BS.Row>
               </BS.Container> */}
        {/* <Content1/> */}
        
        {/* <Container style={{maxWidth: 1250, marginTop: 50}}>
        <BlogList/>
        </Container>
        <WhatCol/>
        <Testimonials/> */}
        {/* <FAQ/> */}
        {/* <Post style={{marginBottom : 50}}>
        <h2 style={{fontSize: 45, textAlign: 'center'}}>BankBank, C'est Ça!</h2>

        <h3 style={{fontSize: 20, fontWeight: 300, textAlign: 'center', marginTop: -10}}>La meilleure banque pour vous</h3>
        <StaticImage
		  	src='../images/bankbank.png' width={1200}
			alt='meilleure banque'
			placeholder='tracedSVG'
			/>
<p>BankBank veut vous aider à trouver la meilleure banque. Voilà. C’est tout. Cette banque peut être traditionnelle avec un guichet physique et un contact humain. Elle peut être une banque en ligne sans papier et sans rendez-vous avec un conseiller. Et, elle peut être une néobanque qui offre une transparence totale et une expérience mobile parfaite. Peu importe. Notre rôle est de déterminer vos besoins et de vous guider pas à pas vers la meilleure offre, celle qui vous correspond.
</p><p>
Le site de BankBank est divisé en 5 comparateurs qui décortiquent les offres de :
</p> 
<ul>
  <li><Link to={'/banque-en-ligne/'}>banques en ligne</Link></li>
  <li><Link to={'/banque-pro/'}>banques pour les professionels</Link></li>
  <li><Link to={'/carte-bancaire/'}>cartes bancaires</Link></li>
  <li><Link to={'/compte-courant/'}>comptes courants</Link></li>
  <li><Link to={'/neobanque/'}>néobanques</Link></li>
</ul>

<p>
BankBank propose aussi des fiches <Link to={'/banque/'}>avis des banques</Link> qui présentent leurs services et tarifs ainsi qu’un avis détaillé sur leur offre.
</p><p>
Et pour finir, notre équipe a créé une <Link to={'/academie/'}>banque académie</Link> qui propose des dizaines de guides pratiques pour vous aider à mieux choisir.
</p><p>
BankBank évoluera avec tout l’univers bancaire qui connaît aujourd’hui des changements et des améliorations encore inimaginables il y’a seulement quelques années. Tout va désormais très vite et toutes les nouveautés ne seront pas forcément bonnes. Notre mission est de faire le tri pour vous, toujours avec cet objectif de vous trouver la meilleure offre bancaire.
</p>   </Post> */}
{/* <HeroFooter /> */}
        <Footer/>
      </PageWrapper>
    </>
  );
};
export default LandingPage2;
