import React, { useState} from 'react'
import * as BS from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'
import { firestore } from "../../utils/firebase"
import { navigate } from 'gatsby-link'
import { useQueryParam, StringParam } from "use-query-params";
import cats from '../data/bankbank/cat.json'


const BankBank = (props) => {
//   const { firebase } = useContext(FirebaseContext);
  const [email, setEmail] = useState('')
  const [sent] = useState(false)
  const [message] = useState('')
 
  const changeEmailHandler = (event) => {
    setEmail(event.target.value)
  }

  const [cat, setCat] = useQueryParam("cat", StringParam);
  console.log('cat', cat)
  // // const db = firebase.firestore();

  let ca = cats.find(Category => Category.Url === cat)
  console.log('ca', ca)
  let categ = ''
  
  if(cats.find(Category => Category.Url === cat)){
    categ = ca['Category']
  }


  

  const addToDB = async () => {
    firestore.collection('bankbank').add({
      email: email,
      date: new Date(),
      tag: categ || 'notag',
    })
    console.log('it worked!!')
  }

  // useEffect(() => {
  //   localStorage.setItem('email', JSON.stringify(email))
  // }, [email])

  const handleSubmit = e => {
    e.preventDefault();
    // setMessage('Thank you for joining! Please check your inbox for your #1 spreadsheet')
    if(email !== '' ){
      // addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      // .then(data => {console.log(data)})
      // .catch(() => {})
      addToDB().then(()=> {
        console.log('it really is')
        navigate('/merci/')
        // window.location.href = '/thanks/dark-kitchen/'

      })
     
      
    }
  }
  
  const [shake, setShake] = useState(true);
    
  const animate = () => {
      
    // Button begins to shake
    setShake(true);
    
    // Buttons tops to shake after 2 seconds
    setTimeout(() => setShake(false));
    
}

var d = new Date();
var day = d.getDate();
var month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
var n = month[d.getMonth()];
var y = d.getFullYear();


    return (
      <> 
      <BS.Container style={{marginTop: -40, marginBottom: 80}}>
      <div className="Newsletter">
    
          <h2>{message}</h2>
   

            <div>
            <h2 style={{textAlign:'center', fontWeight: 300, marginRight: 55, color: 'rgb(28, 45, 53)' }}>Étape 2 / 2</h2>
            <h1 style={{textAlign:'center', fontSize: 45, fontWeight: 300, color: 'rgb(28, 45, 53)'}}>Bienvenue au BankBank Club 🎉</h1>
            <h1 style={{textAlign:'center', fontSize: 55, fontWeight: 800, color: 'rgb(28, 45, 53)'}}>Et maintenant...</h1>
            <h2 style={{textAlign:'center', fontWeight: 300, fontSize: 26, marginRight: 55, color: 'rgb(28, 45, 53)', marginTop: 30 }}>1 - Confirmez votre email ✉️</h2>
           <h4 style={{textAlign:'center', fontWeight: 300, fontSize: 18, margin: 'auto', marginTop: 20, marginBottom: 30, maxWidth: 700}}>Vous allez recevoir un 1er email de <a href='mailto:john@bankbank.fr'>john@bankbank.fr</a> avec votre lien de confirmation (il est peut être dans vos spam).</h4>
           
            <h2 style={{textAlign:'center', fontWeight: 300, fontSize: 26, marginRight: 55, color: 'rgb(28, 45, 53)' }}>2 - Recevez vos offres promos 🎁</h2>
           <h4 style={{textAlign:'center', fontWeight: 300, fontSize: 18, margin: 'auto', marginTop: 20, marginBottom: 30, maxWidth: 700}}>Un 2ème email arrivera avec la liste des toutes dernières promos bancaires.</h4>

            <h2 style={{textAlign:'center', fontWeight: 300, fontSize: 26, marginRight: 55, color: 'rgb(28, 45, 53)' }}>3 - Rendez-vous mardi ⏰!</h2>
           <h4 style={{textAlign:'center', fontWeight: 300, fontSize: 18, margin: 'auto', marginTop: 20, marginBottom: 30, maxWidth: 700}}>Tous les mardi matin, la dernière version de votre newsletter bancaire sera dans vos e-mail.</h4>
            </div>
     
  </div>
      </BS.Container>
      
      </>
    )
}

export default BankBank